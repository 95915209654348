.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.ant-menu {
  border: none;
  padding-bottom: 16px;
}

.navbar-style-div {
  padding: 3px 0px;
  text-align-last: center;
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu {
  color: #272722 !important;
  background: #ffffff 0% 0% no-repeat padding-box;
  font: normal normal 600 12px/22px Segoe UI;
  border-radius: 2px;
  color: #bdbdbd !important;
  opacity: 1;
  border: 2px solid #ffffff !important;
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover {
  color: #272722 !important;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 8px 56px #039be530 !important;
  font: normal normal 600 12px/22px Segoe UI;
  border-radius: 2px;
  opacity: 1;
  border: 2px solid #ffffff !important;
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected {
  background: #ffffff 0% 0% no-repeat padding-box;
  color: #1592e6 !important;
  box-shadow: 0px 0px 0px #fcfcfc30 !important;
  border-radius: 2px;
  opacity: 1;
  border: 2px solid #1592e6 !important;
}

.navbar-logo-title {
  font: normal normal 600 16px/24px Segoe UI;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.grid-row-style {
  align-items: center;
  background: #ffff !important;
}

.main-card-style {
  background: #ffff;
  box-shadow: 0px 16px 40px #039be530;
  opacity: 1;
  margin-top: 3%;
  margin-left: 5%;
  margin-right: 5%;
  /* height: 600px; */
  padding: 16px 40px;
}

.back-button-style {
  font-size: 16px;
  color: #08c;
  margin-top: 0.4%;
  margin-right: 2%;
}

.grid-button-style {
  font-size: 16px;
  color: #08c;
  margin-top: 0.4%;
  margin-left: 1%;
}
.ant-card-meta-description {
  color: black;
  word-wrap: break-word;
  font-size: 12px;
}
.ant-card-body{
  text-align:center;
  width: 100%;
  height: 150px;
}

.ant-upload.ant-upload-select-picture-card {
  text-align:center;
  width: 100%;
  height: 150px;
}
.add-button{
  width: 100%;
  height: 150px;
  background-color: rgb(250 250 250);
}